<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:-10px">
                        Il Laboratorio Fergola è una struttura moderna, occupa una superficie di circa 200 mq di cui la metà circa destinati a laboratorio ed il resto ad aule e servizi accessori.
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/profile.php?id=100057692392868" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.laboratoriofergola.it/#" class="d-block" target="_blank"><i class='bx bxl-linkedin-square'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a href="https://laboratoriofergola.discentya.it/login.php" target="_blank">Docenti</a></li>
                        <li><a href="https://laboratoriofergola.discentya.it/login.php" target="_blank">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via Prof. Luigi Di Donato<br>84048 Santa Maria di Castellabate (SA)</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+393391414723">Telefono: (+39) 339-1414723</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:fergola.stefano@gmail.com">fergola.stefano@gmail.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+393391414723">Lunedì-Venerdì: 09:00÷12:00<br>pomeriggio 15:00÷18:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2024 Laboratorio Fergola - realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2 d-none">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
